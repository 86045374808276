import { deepFreeze } from 'src/util';

const initialState = Object.freeze({});

/**
 * @param {{ [loanGuid: string]: { documents: any[], attachments: any[] } }} state
 * @param {{ type: string, data: { loanGuid: string, attachments: any[], documents: any[], document: any, attachment: any } }} action
 */
const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case 'set_multifamily_filesystem': {
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          documents: data.documents,
          attachments: data.attachments,
        },
      });
    }
    case 'add_multifamily_document': {
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          documents: [
            ...(state[data.loanGuid].documents ?? []),
            { name: data.document, count: 0 },
          ],
        },
      });
    }
    case 'add_multifamily_attachment': {
      const documents = state[data.loanGuid].documents.map((d) =>
        d.name === data.document
          ? {
            ...d,
            count: state[data.loanGuid].attachments.some(
              (att) =>
                att.parentFolderName === d.name &&
                  att.name === data.attachment.name,
            )
              ? d.count
              : (d.count || 0) + 1,
          }
          : d,
      );
      // Update the attachments array: replace existing attachment or add new one
      const attachments = state[data.loanGuid].attachments.map((att) =>
        att.name === data.attachment.name &&
        att.parentFolderName === data.attachment.parentFolderName
          ? { ...att, ...data.attachment }
          : att,
      );

      if (
        !attachments.some(
          (att) =>
            att.name === data.attachment.name &&
            att.parentFolderName === data.attachment.parentFolderName,
        )
      ) {
        attachments.push(data.attachment);
      }
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          attachments,
          documents, // Updated documents array
        },
      });
    }
    case 'delete_multifamily_attachment': {
      const documents = state[data.loanGuid].documents.map((d) =>
        d.name === data.document
          ? { ...d, count: Math.max((d.count || 0) - 1, 0) }
          : d,
      );
      const attachments = state[data.loanGuid].attachments.filter(
        (att) =>
          !(
            att.name === data.attachment.name &&
            att.parentFolderName === data.attachment.parentFolderName
          ),
      );
      return deepFreeze({
        ...state,
        [data.loanGuid]: {
          ...state[data.loanGuid],
          attachments,
          documents,
        },
      });
    }
    case 'update_multifamily_attachment_status': {
      const { loanGuid, folderName, fileName, status } = data;
      // Update the attachments array
      const attachments = state[loanGuid].attachments.map((att) => {
        if (att.name === fileName && att.parentFolderName === folderName) {
          return {
            ...att,
            status: status,
          };
        }
        return att;
      });

      const documents = state[loanGuid].documents;
      return deepFreeze({
        ...state,
        [loanGuid]: {
          ...state[loanGuid],
          attachments,
          documents,
        },
      });
    }
    case 'get_multifamily_attachments_status': {
      const { loanGuid, folderName, fileStatuses } = data;
      let adjustedFolderName =
        folderName === '**Files from before**' ? '' : `${folderName}/`;
      const attachments = state[loanGuid].attachments.map((att) => {
        // Remove IP portion from the file name in fileStatuses to match with att.name
        const attWithIp = Object.keys(fileStatuses).find((nameWithIp) => {
          if (nameWithIp.includes('***')) {
            const extensionIndex = nameWithIp.lastIndexOf('***');
            if (extensionIndex !== -1) {
              const nameWithoutIp = `${nameWithIp.slice(
                0,
                extensionIndex,
              )}${nameWithIp.slice(
                nameWithIp.indexOf('***', extensionIndex) + 3,
              )}`;
              return nameWithoutIp === `${adjustedFolderName}${att.name}`;
            }
          }
          return nameWithIp === `${adjustedFolderName}${att.name}`; // Fallback for names without IP
        });

        if (attWithIp && att.parentFolderName === folderName) {
          return {
            ...att,
            status:
              fileStatuses[`${adjustedFolderName}${att.name}`] || 'Unknown', // Use the status from fileStatuses or fallback
          };
        }

        return att;
      });

      const documents = state[loanGuid].documents;
      return deepFreeze({
        ...state,
        [loanGuid]: {
          ...state[loanGuid],
          attachments,
          documents,
        },
      });
    }
    case 'set_multifamily_attachments_url': {
      const { loanGuid, attachmentName, folderName, url } = data;
      const attachments = state[loanGuid].attachments.map((att) => {
        if (
          att.name === attachmentName &&
          att.parentFolderName === folderName
        ) {
          return {
            ...att,
            url: url,
          };
        }
        return att;
      });
      const documents = state[loanGuid].documents;
      return deepFreeze({
        ...state,
        [loanGuid]: {
          ...state[loanGuid],
          attachments,
          documents,
        },
      });
    }
    case 'update_multifamily_attachment': {
      const { loanGuid, currentFolder, currentName, newFolder, newName } = data;

      // Update the attachments array
      const attachments = state[loanGuid].attachments.map((att) => {
        if (
          att.name === currentName &&
          att.parentFolderName === currentFolder
        ) {
          return {
            ...att,
            name: newName,
            parentFolderName: newFolder,
          };
        }
        return att;
      });

      // Update the documents array
      const documents = state[loanGuid].documents.map((doc) => {
        if (currentFolder !== newFolder) {
          // Decrement count for the current folder if it exists
          if (doc.name === currentFolder) {
            return {
              ...doc,
              count: Math.max((doc.count || 0) - 1, 0),
            };
          }
          // Increment count for the new folder if it exists
          if (doc.name === newFolder) {
            return {
              ...doc,
              count: (doc.count || 0) + 1,
            };
          }
        }
        return doc;
      });

      return deepFreeze({
        ...state,
        [loanGuid]: {
          ...state[loanGuid],
          attachments,
          documents,
        },
      });
    }
    default:
      return state;
  }
};

export default reducer;
