import { apiAxios } from 'src/util';

export const getMultiFamilyDBPropertyValue = async (
  loanGuid,
  DbPropertyName,
) => {
  const res = await apiAxios.get(
    `/sbcmultifamily/loans/${loanGuid}/single-db-property-value/${DbPropertyName}`,
  );
  return res.data;
};

export const updateMultiFamilyLoan = async (data) => {
  const res = await apiAxios.post('/sbcmultifamily/data', data);
  return res.data;
};

export const getMultiFamilyLoan = async (loanGuid) => {
  const res = await apiAxios.get(`/sbcmultifamily/loans/${loanGuid}/data`);
  return res.data;
};

export const updateMultiFamilySponsor = async (data, loanGuid) => {
  const res = await apiAxios.post(
    `/sbcmultifamily/loans/${loanGuid}/sponsor`,
    data,
  );
  return res.data;
};

export const getMultiFamilySponsor = async (loanGuid) => {
  const res = await apiAxios.get(`/sbcmultifamily/loans/${loanGuid}/sponsor`);
  return res.data;
};

export const updateMultiFamilyExpenses = async (data, loanGuid) => {
  const res = await apiAxios.post(
    `/sbcmultifamily/loans/${loanGuid}/expenses`,
    data,
  );
  return res.data;
};

export const getMultiFamilyExpenses = async (loanGuid) => {
  const res = await apiAxios.get(`/sbcmultifamily/loans/${loanGuid}/expenses`);
  return res.data;
};
export const getMultiFamilyT12 = async (loanGuid) => {
  const res = await apiAxios.get(`/sbcmultifamily/loans/${loanGuid}/t12`);
  return res.data;
};

export const updateMultiFamilyRentRoll = async (data, loanGuid) => {
  const res = await apiAxios.post(
    `/sbcmultifamily/loans/${loanGuid}/rent-roll`,
    data,
  );
  return res.data;
};

export const getMultiFamilyRentRoll = async (loanGuid) => {
  const res = await apiAxios.get(`/sbcmultifamily/loans/${loanGuid}/rent-roll`);
  return res.data;
};

export const uploadMultiFamilyFile = async (loanGuid, formData) => {
  const res = await apiAxios.post(
    `/sbcmultifamily/loans/${loanGuid}/files`,
    formData,
  );
  return res.data;
};
export const downloadMultiFamilyFile = async (loanGuid, fileName) => {
  const removeAppendedString = (fileName) => {
    const regex = /\*\*\*.+?\*\*\*/;
    return fileName.replace(regex, '');
  };
  try {
    const res = await apiAxios({
      method: 'get',
      url: `/sbcmultifamily/loans/${loanGuid}?fileName=${fileName}`,
      responseType: 'arraybuffer',
    });
    if (res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', removeAppendedString(fileName));
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      return 'Error Failed to download file';
    }
  } catch (error) {
    return 'Error occurred while downloading file: ' + error;
  }
};

export const deleteMultiFamilyFile = async (loanGuid, fileName) => {
  const res = await apiAxios({
    method: 'delete',
    url: `/sbcmultifamily/loans/${loanGuid}/file/${fileName}`,
  });
  return res.data;
};
export const uploadMultiFamilyTemplate = async (loanGuid, formData) => {
  const res = await apiAxios.patch(
    `/sbcmultifamily/loans/${loanGuid}/upload-template`,
    formData,
  );
  return res.data;
};
export const downloadMultiFamilyTemplate = async (loanGuid, createNewFile) => {
  try {
    const res = await apiAxios({
      method: 'get',
      url: `/sbcmultifamily/loans/${loanGuid}/download-template/${createNewFile}`,
      responseType: 'arraybuffer',
    });
    if (res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'multiFamilyTemplate.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      return 'Error Failed to download file';
    }
  } catch (error) {
    return 'Error occurred while downloading file: ' + error;
  }
};

export const downloadMultiFamilyRentRollTemplate = async (type) => {
  try {
    const res = await apiAxios({
      method: 'get',
      url: `/sbcmultifamily/download-template/rentroll?type=${type ?? ''}`,
      responseType: 'arraybuffer',
    });
    if (res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Rentroll_template.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      return 'Error Failed to download file';
    }
  } catch (error) {
    return 'Error occurred while downloading file: ' + error;
  }
};

export const getMultiFamilyCosts = async (loanGuid) => {
  const res = await apiAxios.get(`/sbcmultifamily/loans/${loanGuid}/costs`);
  return res.data;
};

export const updateMultiFamilyCosts = async (loanGuid, data) => {
  await apiAxios.post(`/sbcmultifamily/loans/${loanGuid}/costs`, data);
};

export const downloadMultiFamilyT12Template = async () => {
  try {
    const res = await apiAxios({
      method: 'get',
      url: '/sbcmultifamily/download-template/t12',
      responseType: 'arraybuffer',
    });
    if (res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'T12_template.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      return 'Error Failed to download file';
    }
  } catch (error) {
    return 'Error occurred while downloading file: ' + error;
  }
};
export const uploadMultiFamilyT12 = async (loanGuid, formData) => {
  try {
    const res = await apiAxios.post(
      `/sbcmultifamily/loans/${loanGuid}/t12-template`,
      formData,
    );
    return res.data;
  } catch (error) {
    return 'Error template is in bad format';
  }
};

export const downloadMultiFamilyExpensesTemplate = async () => {
  try {
    const res = await apiAxios({
      method: 'get',
      url: '/sbcmultifamily/download-template/expenses',
      responseType: 'arraybuffer',
    });
    if (res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Expenses_template.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      return 'Error Failed to download file';
    }
  } catch (error) {
    return 'Error occurred while downloading file: ' + error;
  }
};
export const uploadMultiFamilyExpenses = async (loanGuid, formData) => {
  try {
    const res = await apiAxios.post(
      `/sbcmultifamily/loans/${loanGuid}/expenses-template`,
      formData,
    );
    return res.data;
  } catch (error) {
    return 'Error template is in bad format';
  }
};

export const submitMultifamilyLoan = async (loanGuid) => {
  const res = await apiAxios.post(
    `/sbcmultifamily/loans/${loanGuid}/submit-loan`,
  );
  return res.data;
};

export const getMultiFamilyLoanFiles = async (loanGuid) => {
  const res = await apiAxios.get(
    `/sbcmultifamily/loans/${loanGuid}/files-info`,
  );
  return res.data;
};

export const getRentRollData = async (loanGuid, resetColumns = false) => {
  const res = await apiAxios.get(
    `/sbcmultifamily/loans/${loanGuid}/rentroll-data/${resetColumns}`,
  );
  return res.data;
};
export const getLendersData = async (loanGuid) => {
  const res = await apiAxios.get(`/sbcmultifamily/loans/${loanGuid}/lenders`);
  return res.data;
};

export const saveMultiFamilyClosingFees = async (data, loanGuid) => {
  const res = await apiAxios.post(
    `/sbcmultifamily/loans/${loanGuid}/closing-fees`,
    data,
  );
  return res.data;
};
export const updateMultiFamilyClosingFees = async (data, loanGuid, status) => {
  const res = await apiAxios.patch(
    `/sbcmultifamily/loans/${loanGuid}/closing-fees/${status}`,
    data,
  );
  return res.data;
};
export const getMultiFamilyClosingFees = async (loanGuid) => {
  const res = await apiAxios.get(
    `/sbcmultifamily/loans/${loanGuid}/closing-fees`,
  );
  return res.data;
};

export const updateMultiFamilySummary = async (data) => {
  const res = await apiAxios.post(
    `/sbcmultifamily/loans/${data.loanGuid}/summary`,
    data,
  );
  return res.data;
};

export const getMultiFamilySummary = async (loanGuid) => {
  const res = await apiAxios.get(`/sbcmultifamily/loans/${loanGuid}/summary`);
  return res.data;
};

export const downloadMultiFamilySetupExcel = async (
  loanGuid,
  amortizationSchedule,
) => {
  try {
    const res = await apiAxios({
      method: 'post',
      url: `/sbcmultifamily/loans/${loanGuid}/download-setup-template`,
      responseType: 'arraybuffer',
      data: { amortizationSchedule: amortizationSchedule },
    });
    if (res.status === 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'multiFamilySetup.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      return 'Error Failed to download file';
    }
  } catch (error) {
    return 'Error occurred while downloading file: ' + error;
  }
};
export const downloadMultiFamilySetupExcelPrecheck = async (loanGuid) => {
  const res = await apiAxios.get(
    `/sbcmultifamily/loans/${loanGuid}/setup-template-check-info`,
  );
  return res.data;
};

export const createBorrowerInvite = async (
  loanGuid,
  email,
  addressLineText,
  type,
) => {
  const res = await apiAxios.get(
    `/sbcmultifamily/loans/${loanGuid}/create-borrower-portal/${email}?address=${addressLineText}&type=${type}`,
  );
  return res.data;
};
export const processBorrowerPortalRequest = async (link, password) => {
  try {
    //const sanitizedLink = encodeURIComponent(link);
    const res = await apiAxios.get(
      `/sbcmultifamily/password/${password}?link=${link}`,
    );
    return res.data;
  } catch (err) {
    return err;
  }
};
export const borrowerFileUpload = async (link, password, type, formData) => {
  try {
    const res = await apiAxios.post(
      `/sbcmultifamily/password/${password}/type/${type}?link=${link}`,
      formData,
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const orderCreditMultifamilyLoan = async (loanGuid, sponsor) => {
  const res = await apiAxios.post(
    `/sbcmultifamily/loans/${loanGuid}/orderCredit`,
    sponsor,
  );
  return res.data;
};

export const orderCreditRefreshMultifamilyLoan = async (loanGuid, sponsor) => {
  const res = await apiAxios.post(
    `/sbcmultifamily/loans/${loanGuid}/orderCreditRefresh`,
    sponsor,
  );
  return res.data;
};

export const updateCreditMultifamilyLoan = async (loanGuid, sponsor) => {
  const res = await apiAxios.post(
    `/sbcmultifamily/loans/${loanGuid}/updateCredit`,
    sponsor,
  );
  return res.data;
};

export const getCreditMultifamilyLoan = async (orderId) => {
  const res = await apiAxios.get(`/sbcmultifamily/retrieveCredit/${orderId}`);
  return res.data;
};

export const sendCreditAuthorization = async (sponsor, loanGuid, appIndex) => {
  const res = await apiAxios.post(
    `/sbcmultifamily/loans/${loanGuid}/creditAuthorization/${appIndex}`,
    sponsor,
  );
  return res.data;
};

export const syncCommercialToMultifamilyDataInEncompassAsync = async (
  loanGuid,
) => {
  await apiAxios.get(`/sbcmultifamily/loans/${loanGuid}/sync-data`);
};

export const fetchMultifamilyFileSystem = (loanGuid) => async (dispatch) => {
  const res = await apiAxios.get(
    `sbcmultifamily/loans/${loanGuid}/folder-info`,
  );
  dispatch({
    type: 'set_multifamily_filesystem',
    data: {
      loanGuid,
      documents: res.data.documents,
      attachments: res.data.attachments,
    },
  });
};
export const createMultifamilyDocument =
  (loanGuid, title) => async (dispatch) => {
    await apiAxios.post(
      `sbcmultifamily/loans/${loanGuid}/create-folder/${title}`,
    );
    dispatch({
      type: 'add_multifamily_document',
      data: {
        loanGuid,
        document: title,
      },
    });
  };

export const uploadMultifamilyAttachment =
  (loanGuid, documentName, file) => async (dispatch) => {
    const formData = new FormData();
    formData.append('file', file);

    const res = await apiAxios.post(
      `sbcmultifamily/loans/${loanGuid}/create-file/${documentName}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    dispatch({
      type: 'add_multifamily_attachment',
      data: {
        loanGuid,
        document: documentName,
        attachment: res.data,
      },
    });
  };

export const deleteMultifamilyAttachment =
  (loanGuid, attachmentName, foldername) => async (dispatch) => {
    await apiAxios.delete(
      `sbcmultifamily/loans/${loanGuid}/file/${attachmentName}/folder/${foldername}`,
    );
    dispatch({
      type: 'delete_multifamily_attachment',
      data: {
        loanGuid,
        attachment: {
          name: attachmentName,
          parentFolderName: foldername,
        },
        document: foldername,
      },
    });
  };

export const updateFileNameAndFolder =
  (loanGuid, currentFolder, currentName, newFolder, newName) =>
    async (dispatch) => {
      let currentFolderName =
      currentFolder === '**Files from before**' ? '' : `${currentFolder}/`;
      const currentFilePath = `${currentFolderName}${currentName}`;
      let newFolderName =
      newFolder === '**Files from before**' ? '' : `${newFolder}/`;
      const newFilePath = `${newFolderName}${newName}`;

      await apiAxios.patch(
      `sbcmultifamily/loans/${loanGuid}/file-current-path/${encodeURIComponent(
        currentFilePath,
      )}/file-new-path/${encodeURIComponent(newFilePath)}`,
      );
      dispatch({
        type: 'update_multifamily_attachment',
        data: {
          loanGuid,
          currentFolder,
          currentName,
          newFolder,
          newName,
        },
      });
    };

export const updateMultifamilyFileStatus =
  (loanGuid, folderName, fileName, status) => async (dispatch) => {
    let currentFolderName =
      folderName === '**Files from before**' ? '' : `${folderName}/`;
    const currentFilePath = `${currentFolderName}${fileName}`;
    await apiAxios.post(
      `sbcmultifamily/loans/${loanGuid}/file-status/${encodeURIComponent(
        currentFilePath,
      )}/status/${status}`,
    );
    dispatch({
      type: 'update_multifamily_attachment_status',
      data: {
        loanGuid,
        folderName,
        fileName,
        status,
      },
    });
  };

export const getFileStatusForFolder =
  (loanGuid, folderName, fileNames) => async (dispatch) => {
    try {
      const queryParams = fileNames
        .map((filePath) => `filePaths=${encodeURIComponent(filePath)}`)
        .join('&');
      const resp = await apiAxios.get(
        `sbcmultifamily/loans/${loanGuid}/file-status?${queryParams}`,
      );

      dispatch({
        type: 'get_multifamily_attachments_status',
        data: {
          loanGuid: loanGuid,
          folderName: folderName,
          fileStatuses: resp.data,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

export const fetchMultifamilyAttachmentUrl =
  (loanGuid, documentName, modifiedAttachmentName, attachmentName) =>
    async (dispatch) => {
      let modifiedDocumentName =
      documentName === '**Files from before**' ? '' : `${documentName}/`;
      const resp = await apiAxios.get(
      `sbcmultifamily/loans/${loanGuid}/file-preview/${encodeURIComponent(
        `${modifiedDocumentName}${modifiedAttachmentName}`,
      )}`,
      );
      dispatch({
        type: 'set_multifamily_attachments_url',
        data: {
          loanGuid: loanGuid,
          attachmentName: attachmentName,
          folderName: documentName,
          url: resp.data,
        },
      });
    };
