import { useSelector } from 'react-redux';
import { RootStore } from 'src/types';
import {
  MultifamilyDocument,
  MultifamilyAttachment,
  MultifamilyFolder,
} from 'src/types';

const emptyList = [];

export const useLoanMultifamilyFileSystem = (
  loanGuid: string,
): MultifamilyFolder | null => {
  const multifamilyFolder = useSelector<
    RootStore,
    Record<string, MultifamilyFolder>
  >((store) => store.multifamilyFolder);
  return multifamilyFolder[loanGuid] || null;
};

export const useLoanMultifamilyFolderDocuments = (
  loanGuid: string,
): MultifamilyDocument[] => {
  const multifamilyFolder = useLoanMultifamilyFileSystem(loanGuid);
  return multifamilyFolder?.documents ?? emptyList;
};
export const useLoanNonBorrowerFolderDocumentNames = (
  loanGuid: string,
): string[] => {
  const documents = useLoanMultifamilyFolderDocuments(loanGuid);
  const isEmailFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return documents
    .map((doc) => doc.name)
    .filter(
      (name) => !isEmailFormat.test(name) && name !== '**Files from before**',
    );
};
export const useLoanMultifamilyFolderAttachments = (
  loanGuid: string,
): MultifamilyAttachment[] => {
  const multifamilyFolder = useLoanMultifamilyFileSystem(loanGuid);
  return multifamilyFolder?.attachments ?? emptyList;
};

export const useLoanMultifamilySpecificFolderAttachments = (
  loanGuid: string,
  folderName: string,
): MultifamilyAttachment[] => {
  const multifamilyFolder = useLoanMultifamilyFileSystem(loanGuid);
  return (
    multifamilyFolder?.attachments?.filter(
      (att) => att.parentFolderName === folderName,
    ) ?? emptyList
  );
};

export const useLoanMultifamilyFolderAttachment = (
  loanGuid: string,
  attachmentName: string,
  parentFolderName: string,
) => {
  const attachments = useLoanMultifamilyFolderAttachments(loanGuid);
  return attachments?.find(
    (attachment) =>
      attachment.name === attachmentName &&
      attachment.parentFolderName === parentFolderName,
  );
};
